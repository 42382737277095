.App {
  text-align: center;
  /*background: linear-gradient(-45deg, #2a0902, #1d0a05, #f4775b, #270c06);
  animation: gradientBG 15s ease infinite;
  background-size: 400% 400%;*/
  background-color: #0a0a0a;  /* Change background color to a dark grey */
  color: #f0f0f0;  /* Change text color to a light grey */
}

/* Add this to your CSS */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-title h1 {
  text-align: left;
}

.connect {
  margin-right: 1px;
}


/* keyframes for changing the body background color */
@keyframes gradientBG {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  1000% {
      background-position: 0% 50%;
  }

}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.title a {
  color: #f4775b;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 2rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.description ul {
  list-style-type: disc; /* Use a bullet point for list item marker */
  padding-left: 20px; /* Add left padding to indent the list */
}

.description li {
  margin-bottom: 10px; /* Add some spacing between list items */
  line-height: 1.6; /* Set line height to improve readability */
  font-size: 1.2rem; /* Adjust font size */
  text-align: left; /* Set text alignment */
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto; /* center the grid */
}

.card {
  margin: 1rem;
  padding: 2rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #444444;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  background-color: #1a1a1a;
  max-width: 100%;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* add some shadow */
  margin-bottom: 5rem;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  color: #f56342; /* add some color to draw attention */
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #ddd; /* lighter text color */
}

.feedback p {
  color: #aaa; /* lighter text color */
}

.feedback a {
  color: #f56342; /* use the same color as the mint button */
}

.logo {
  height: 512px; /* Adjust as needed */
  margin-top: 30px; /* Adjust as needed */
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.cardForm {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.input {
  width: 70%;
  height: 40px;
  color: white;
  background: none;
  border: none;
  border-bottom: 2px solid white;
}

.button {
  width: 30%;
  height: 45px;
  margin-left: 5px;
  background: #f56342;
  border: none;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease; /* Added transition for a smoother color change */
}

.button:hover {
  background: #e45232; /* Darken the button color slightly when hovered */
}

.connect {
  margin-top: 20px;
  width: 120px;
  height: 45px;
  background: #f56342;
  border: none;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease; /* Added transition for a smoother color change */
}

.connect:hover {
  background: #e45232; /* Darken the button color slightly when hovered */
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #1a1a1a; /* Use the same dark grey as in the dark theme */
  color: white;
  text-align: center;
  padding: 10px 0;
}

.footer a {
  color: #f56342; /* Use the same orange as in the buttons */
  text-decoration: none;
}

.footer a:hover,
.footer a:focus,
.footer a:active {
  text-decoration: underline;
}
